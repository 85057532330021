.header {
  position: relative;
  z-index: 2;
  display: flex;
  max-width: 1170px;
  flex-direction: column;
  padding: 1.5% 0.5% 0.5% 0.5%;
  margin: 0 auto;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
}
.header__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 40px 0;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  -webkit-align-items: flex-end;
}
.header__box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  -webkit-align-items: center;
  -webkit-align-self: center;
}
.header__logo {
  width: 205px;
  height: 35px;
  margin: 0 24px 0 0;
  align-self: start;
}
.header__text {
  max-width: 156px;
  font-family: "Open Sans Condensed";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  color: #1b1b1b;
  text-align: left;
  margin: 0;
}
.header__text-messenger {
  max-width: 97px;
  font-family: "Open Sans Condensed";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  color: #1b1b1b;
  text-align: right;
  margin: 0 24px 0 0;
}
.header__text-tel {
  font-family: "Open Sans Condensed";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  color: #1b1b1b;
  text-align: right;
  margin: 5px 0 0 0;
}
.header__link {
  text-decoration: none;
}
.header__messenger {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}
.header__tel {
  font-family: "Open Sans Condensed";
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: #1b1b1b;
  text-align: right;
  text-decoration: none;
  margin: 0;
}
.header__nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  -webkit-align-items: center;
}
.nav__link {
  max-width: 133px;
  font-family: "Open Sans Condensed";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  color: #1b1b1b;
  text-align: right;
  text-decoration: none;
}
.header__container_mob {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 0 10px;
  -webkit-justify-content: space-around;
  -webkit-align-items: flex-end;
}
@media screen and (max-width: 819px) {
  .header__nav {
    display: none;
  }
  .header__container_mob {
    display: flex;
    display: -webkit-flex;
  }
  .header__button-nav {
    width: 40px;
    height: 40px;
    background-color: #5ebf47;
    background-image: url(../../images/menu.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 56%;
    outline: none;
    cursor: pointer;
    border: none;
  }
  .header__logo {
    width: 116px;
    height: 19px;
    margin: 7px 0 0 20px;
    align-self: start;
  }
  .header__container {
    display: none;
  }
  .header__nav_mob {
    border-radius: 5px;
    top: 75px;
    left: 15px;
    background-color: rgba(94, 191, 71, 0.8);
    position: absolute;
    z-index: 0;
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    -webkit-justify-content: space-around;
    -webkit-align-items: flex-start;
    -webkit-box-orient: vertical;
  }
  .header__nav_active {
    display: flex;
    display: -webkit-flex;
  }
  .nav__link {
    margin: 5px 10px;
    color: #f7f7f7;
  }
  .header__tel {
    font-size: 18px;
  }
  .header__text-tel {
    font-size: 12px;
    margin: 5px 0 5px 0;
  }
}
@media screen and (max-width: 328px) {
  .header__logo {
    margin: 7px 0 0 11px;
  }
}
