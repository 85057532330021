.popup {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  z-index: 2;
}
.popup_on {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
}
.popup__container {
  display: none;
  margin: auto;
  position: relative;
}
.popup__container_active {
  display: flex;
  display: -webkit-flex;
}
.popup__form {
  max-width: 320px;
  position: relative;
  padding: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: rgba(94, 191, 71, 0.8);
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  -webkit-box-orient: vertical;
}
.popup__close {
  width: 26px;
  height: 26px;
  background-image: url(../../images/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  padding: 0;
  top: 1%;
  left: 90%;
  transition: linear 0.5s;
}
.popup__close:hover {
  opacity: 0.6;
}
