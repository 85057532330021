@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./OpenSansCondensed-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans Condensed";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./OpenSansCondensed-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(./OpenSansCondensed-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./Vollkorn-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(./Vollkorn-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(./Vollkorn-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url(./Vollkorn-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url(./Vollkorn-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(./Vollkorn-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./Vollkorn-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(./Vollkorn-SemiBoldItalic.ttf) format("truetype");
}
