html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
body {
  margin: 0;
}
.page {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}
.section__title {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: 300;
  font-size: 45px;
  line-height: 1.3;
  text-align: center;
  color: #1b1b1b;
  margin: 0 0 33px 0;
}
.section__subtitle {
  max-width: 450px;
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 1.3;
  text-align: center;
  color: #1b1b1b;
  margin: 0 auto 42px auto;
}
@media screen and (max-width: 865px) {
  .section__title {
    font-size: 35px;
    margin: 0 0 27px 0;
  }
}
@media screen and (max-width: 685px) {
  .section__title {
    font-size: 25px;
  }
  .section__subtitle {
    font-size: 15px;
    margin: 0 auto 35px auto;
  }
}
