.form {
  margin: 0 auto;
}
.form__main {
  max-width: 939px;
  padding: 27px 26px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-image: url(../../images/header_form.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  -webkit-align-items: center;
}
.sertificate__form {
  max-width: 887px;
  padding: 27px 26px;
  margin: 0 auto;
}
.form__input {
  background: #ffffff;
  width: 158px;
  height: 52px;
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
  color: #1b1b1b;
  border: none;
  border-radius: 26px;
  margin: 0 29px 0 0;
  padding: 0;
  outline: none;
  cursor: pointer;
}
.form__input_green {
  background: #d1efce;
}
.form__name {
  background-image: url(../../images/user.svg);
  background-position: 24px 20px;
  background-repeat: no-repeat;
  background-size: 5.5%;
  padding-left: 42px;
}
.form__tel {
  background-image: url(../../images/phone.svg);
  background-position: 24px 20px;
  background-repeat: no-repeat;
  background-size: 5.5%;
  padding-left: 42px;
}
.form__number {
  width: 116px;
  background-image: url(../../images/team.svg);
  background-position: 24px 20px;
  background-repeat: no-repeat;
  background-size: 5.5%;
  padding-left: 42px;
  padding-right: 42px;
}
.form__email {
  background-image: url(../../images/at.svg);
  background-position: 24px 20px;
  background-repeat: no-repeat;
  background-size: 5.5%;
  padding-left: 42px;
}
.form__button {
  background: #ffffff;
  width: 191px;
  height: 43px;
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #40a03b;
  border: 2px solid #40a03b;
  border-radius: 26px;
  margin: 4.5px;
  padding: 0;
  outline: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 1);
}
.form__button_green {
  background: #5ebf47;
  color: #ffffff;
  border: 2px solid #ffffff;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(94, 191, 71, 1);
  -moz-box-shadow: 0px 0px 0px 5px rgba(94, 191, 71, 1);
  box-shadow: 0px 0px 0px 5px rgba(94, 191, 71, 1);
}
.form__title {
  font-family: "Volkorn";
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  margin: 0 auto;
}
.form__subtitle {
  max-width: 378px;
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  margin: 37px auto 42px auto;
}
@media screen and (max-width: 983px) {
  .form__subcribe {
    max-width: 263px;
    padding: 50px 48px;
    margin: 99px auto 0 auto;
    border-radius: 9px;
  }
  .form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: space-around;
    -webkit-align-items: center;
    -webkit-box-orient: vertical;
  }
  .form__input {
    margin: 0 auto 35px auto;
  }
  .form__button {
    margin: 4.5px auto;
  }
  .form__img {
    width: 130px;
    height: 124px;
    top: -16%;
    left: 233px;
  }
  .form__main {
    display: none;
  }
}
@media screen and (max-width: 366px) {
  .form__img {
    top: -16%;
    left: 186px;
  }
}
